<template>
  <div class="cs-container" style="background: white">
    <div
      class="d-print-none mb-2 flex items-center justify-end"
      style="margin-right: 80px"
    >
      <b-button variant="primary" @click="handlePrint">In Phiếu</b-button>
    </div>

    <header-form></header-form>

    <div class="d-flex align-items-center justify-content-center mx-auto w-90">
      <!-- <img class="cs-img col-6" src="@/assets/images/pages/AGTC-logo.png" /> -->
      <h6 class="text-center col-10 title-color">PHIẾU YÊU CẦU XÉT NGHIỆM</h6>
      <span class="close-icon d-print-none" @click="backToDetail">&times;</span>
    </div>
    <div id="printOrderForm" class="my-3 cs-border-b">
      <b-row>
        <b-col>
          <div>
            <div class="cs-flex gap-0">
              <div class="col-4 p-0">
                <span class="cs-label">Họ và tên: </span>
                <span class="cs-content" v-html="form.full_name"></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label">Năm sinh (tuổi): </span>
                <span class="cs-content" v-html="form.age"></span>
              </div>
              <div class="col-4 p-0">
                <div
                  class="mr-1 cs-checkbox"
                  style="display: inline-flex"
                  :set="(isMale = form.gender === 1)"
                >
                  <label class="mr-2" style="font-size: 18px">Nam</label>
                  <input
                    v-model="isMale"
                    id="male"
                    name="male"
                    type="checkbox"
                  />
                </div>
                <div
                  class="mr-1 cs-checkbox"
                  style="display: inline-flex"
                  :set="(isFemale = form.gender === 2)"
                >
                  <label class="mr-2" style="font-size: 18px">/ Nữ</label>
                  <input
                    v-model="isFemale"
                    id="female"
                    name="female"
                    type="checkbox"
                  />
                </div>
              </div>
            </div>
            <div class="cs-flex gap-0">
              <div class="col-8 p-0">
                <span class="cs-label">Địa chỉ: </span>
                <span class="cs-content" v-html="form.address"></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label">Điện thoại: </span>
                <span class="cs-content" v-html="form.phone_number"></span>
              </div>
            </div>
            <div class="cs-flex">
              <div class="col-12 p-0">
                <span class="cs-label">Chẩn đoán: </span>
                <span
                  class="cs-content"
                  v-html="form.diagnostic_results"
                ></span>
                <template v-if="!form.diagnostic_results">
                  <br /><br /><br />
                </template>
              </div>
            </div>
            <br />
            <div class="cs-width-full cs-flex">
              <div class="container-border cs-width-full">
                <div class="d-flex" style="background-color: #0569c5">
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex justify-center text-white">
                      TÊN XÉT NGHIỆM
                    </div>
                  </div>
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex justify-center text-white">
                      MẪU BỆNH PHẨM
                    </div>
                  </div>
                </div>
                <div class="d-flex cs-border-t">
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex mh-100">
                      {{
                        form.test_selection && form.test_selection.product_name
                      }}
                    </div>
                  </div>
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex mh-100">
                      {{
                        form.test_selection && form.test_selection.test_sample
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cs-width-full cs-flex">
              <div class="cs-width-full">
                <div class="d-flex">
                  <div class="col-6 p-2">Ngày</div>
                  <div class="col-6 p-2">Ngày</div>
                </div>
                <div class="d-flex">
                  <div class="col-6 px-2">
                    Chữ ký người tham gia xét nghiệm<br /><br /><br />
                  </div>
                  <div class="col-6 px-2">Chữ ký bác sĩ tư vấn</div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- <header-form></header-form>

    <div class="d-flex align-items-center justify-content-center mx-auto w-90">
      <img class="cs-img col-6" src="@/assets/images/pages/AGTC-logo.png" /> 
      <h6 class="text-center col-10 title-color">PHIẾU YÊU CẦU XÉT NGHIỆM</h6>
    </div>
    <div id="printOrderForm" v-if="form.other_person" class="my-3">
      <b-row>
        <b-col>
          <div>
            <div class="cs-flex gap-0">
              <div class="col-4 p-0">
                <span class="cs-label">Họ và tên: </span>
                <span
                  class="cs-content"
                  v-html="form.other_person.full_name"
                ></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label">Năm sinh (tuổi): </span>
                <span class="cs-content" v-html="form.other_person.age"></span>
              </div>
              <div class="col-4 p-0">
                <div
                  class="mr-1 cs-checkbox"
                  style="display: inline-flex"
                  :set="(isMale = form.other_person.gender === 1)"
                >
                  <label class="mr-2" style="font-size: 18px">Nam</label>
                  <input
                    v-model="isMale"
                    id="male"
                    name="male"
                    type="checkbox"
                  />
                </div>
                <div
                  class="mr-1 cs-checkbox"
                  style="display: inline-flex"
                  :set="(isFemale = form.other_person.gender === 2)"
                >
                  <label class="mr-2" style="font-size: 18px">/ Nữ</label>
                  <input
                    v-model="isFemale"
                    id="female"
                    name="female"
                    type="checkbox"
                  />
                </div>
              </div>
            </div>
            <div class="cs-flex gap-0">
              <div class="col-8 p-0">
                <span class="cs-label">Địa chỉ: </span>
                <span
                  class="cs-content"
                  v-html="form.other_person.address"
                ></span>
              </div>
              <div class="col-4 p-0">
                <span class="cs-label">Điện thoại: </span>
                <span
                  class="cs-content"
                  v-html="form.other_person.phone_number"
                ></span>
              </div>
            </div>
            <div class="cs-flex">
              <div class="col-12 p-0">
                <span class="cs-label">Chẩn đoán: </span>
                <span
                  class="cs-content"
                  v-html="form.other_person.diagnostic_results"
                ></span>
                <template v-if="!form.other_person.diagnostic_results">
                  <br /><br /><br />
                </template>
              </div>
            </div>
            <br />
            <div class="cs-width-full cs-flex">
              <div class="container-border cs-width-full">
                <div class="d-flex" style="background-color: #0569c5">
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex justify-center text-white">
                      TÊN XÉT NGHIỆM
                    </div>
                  </div>
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex justify-center text-white">
                      MẪU BỆNH PHẨM
                    </div>
                  </div>
                </div>
                <div class="d-flex cs-border-t">
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex mh-100">
                      {{
                        form.other_person.test_selection &&
                        form.other_person.test_selection.product_name
                      }}
                    </div>
                  </div>
                  <div class="col-6 p-0 cs-border-r">
                    <div class="cs-flex mh-100">
                      {{
                        form.other_person.test_selection &&
                        form.other_person.test_selection.test_sample
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cs-width-full cs-flex">
              <div class="cs-width-full">
                <div class="d-flex">
                  <div class="col-6 p-2">Ngày</div>
                  <div class="col-6 p-2">Ngày</div>
                </div>
                <div class="d-flex">
                  <div class="col-6 px-2">
                    Chữ ký người tham gia xét nghiệm<br /><br /><br />
                  </div>
                  <div class="col-6 px-2">Chữ ký bác sĩ tư vấn</div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>-->
  </div>
</template>
    
<script>
import { DEFAULT_CIPHERS } from 'tls'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'
import HeaderForm from '@/views/PrintOrderForm/HeaderForm.vue'

const DEFAULT_FORM = {
  full_name: '',
  birthday: '',
  gender: null,
  address: '',
  phone_number: '',
  doctor: '',
  clinic: '',
  clinic_phone_number: '',
  sample_taken_at: '',
  test_code: '',
  requirements: [],
  cancer_history: [],
  test_selection: {
    product_name: '',
    test_sample: ''
  },
  other_person: {
    full_name: '',
    birthday: '',
    gender: null,
    address: '',
    phone_number: '',
    doctor: '',
    clinic: '',
    clinic_phone_number: '',
    sample_taken_at: '',
    test_code: '',
    requirements: [],
    cancer_history: [],
    test_selection: {
      product_name: '',
      test_sample: ''
    }
  }
}

export default {
  name: 'PrintOrderConsentForm',
  components: {
    HeaderForm
  },
  data() {
    return {
      form: { ...DEFAULT_FORM },
      DEFAULT_FORM
    }
  },
  computed: {
    ...mapState({
      orders: (state) => state.orders
    }),
    dateNow() {
      const date = new Date()
      return `Ngày ${date.getDate()} tháng ${
        date.getMonth() + 1
      } năm ${date.getFullYear()}`
    }
  },
  created() {
    if (this.$route.params.id) {
      this.handleMapData()
    }
  },
  methods: {
    formatDMY(date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    handleMapData() {
      this.form = {
        ...this.orders.requisitionFormV2
      }
      if (isEmpty(this.form)) this.form = { ...DEFAULT_FORM }
    },
    handlePrint() {
      document.title = 'test-require-form'
      window.print()
    },
    backToDetail() {
      document.title = 'HODO Partner'
      this.$router.replace({
        name: 'orderDetail',
        params: {
          id: this.orders.viewDetail.id
        },
        query: {
          step: 1
        }
      })
    },
    handleFormatDate(date) {
      if (!date) return ''
      return moment(date).format('DD-MM-YYYY')
    }
  }
}
</script>
    
<style lang="scss" scoped>
.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: 'Times New Roman', 'Tinos', Times, serif !important;
    font-size: 18px;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .w-90 {
    width: 90%;
  }
  .w-80 {
    width: 80%;
  }
  .w-30 {
    width: 30%;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
  .flex-grow-4 {
    flex-grow: 4;
  }
  .flex-grow-2 {
    flex-grow: 2;
  }
  &.gap-0 {
    gap: 0px;
  }
}

// .cs-label {
// width: 150px;
// }
.bold {
  font-weight: bold;
}

// .cs-border-b {
//   border-bottom: 1px solid #5c5c5c;
// }
.cs-border-t {
  border-top: 1px solid #5c5c5c;
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: baseline;
  input {
    transform: translateY(3px);
    width: 18px;
    height: 18px;
  }

  input[type='checkbox'] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}
.mh-100 {
  min-height: 100px;
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  color: #0569c5;
  font-size: 32px !important;
}

@media print {
  body,
  html {
    height: 100vh;
    * {
      color: black !important;
    }
  }
  .text-white {
    color: white !important;
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>